<template>
  <div
    class="c-app flex-row align-items-center"
    style="background-image: url(/img/background-batik-putih.png)"
  >
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <img src="/img/logo.png" />
        </CCol>
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <ValidationObserver v-slot="{ handleSubmit }">
                  <CForm @submit.stop.prevent="handleSubmit(submit)">
                    <h1>Login</h1>
                    <p class="text-muted">Sign In to your account</p>
                    <validation-provider
                      rules="required"
                      v-slot="{ errors }"
                      name="Username"
                    >
                      <CInput
                        placeholder="Username"
                        autocomplete="username"
                        v-model="form.username"
                        :invalid-feedback="errors[0]"
                        :isValid="!errors[0]"
                      >
                        <template #prepend-content>
                          <CIcon name="cil-user" />
                        </template>
                      </CInput>
                    </validation-provider>
                    <validation-provider
                      rules="required"
                      v-slot="{ errors }"
                      name="Password"
                    >
                      <CInput
                        placeholder="Password"
                        type="password"
                        v-model="form.password"
                        autocomplete="curent-password"
                        :invalid-feedback="errors[0]"
                        :isValid="!errors[0]"
                      >
                        <template #prepend-content>
                          <CIcon name="cil-lock-locked" />
                        </template>
                      </CInput>
                    </validation-provider>
                    <CRow>
                      <CCol col="6" class="text-left">
                        <CSpinner color="success" size="sm" v-if="loading" />
                        <CButton
                          color="primary"
                          class="px-4"
                          type="submit"
                          v-else
                          >Login</CButton
                        >
                      </CCol>
                      <CCol col="6" class="text-right">
                        <!-- <CButton color="link" class="px-0">Forgot password?</CButton>
                        <CButton color="link" class="d-lg-none">Register now!</CButton>-->
                      </CCol>
                    </CRow>
                  </CForm>
                </ValidationObserver>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>SIAKANG</h2>
                <p>Kabupaten Purwakarta</p>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      errorMessage: null,
      form: {
        username: "",
        password: "",
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", { user: "user" }),
  },
  methods: {
    async submit() {
      this.loading = true;
      let status = await this.$store.dispatch("auth/login", this.form);
      if (status == 200 || status == 201)
        await this.$store.dispatch("auth/fetchUser");
      this.loading = false;

      this.$router.push({ name: "Home" });
    },
  },
};
</script>
